import React, { Fragment } from 'react';
import StorySection from '../../components/StorySection';

import CoupleSection3 from '../../components/CoupleSection3/CoupleSection3';
import EventSection3 from '../../components/EventSection3/EventSection3';
import Footer from '../../components/footer/Footer';
import Hero2 from '../../components/hero2/hero2';
import Navbar from '../../components/Navbar/Navbar';
import RsvpSectionS3 from '../../components/RsvpSectionS3/RsvpSectionS3';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = () => {
  return (
    <Fragment>
      <Navbar />
      <Hero2 />
      <CoupleSection3 cClass={'pt-160'} />
      <StorySection />
      {/* <PortfolioSection /> */}
      <RsvpSectionS3
        bg={
          'https://cf.bstatic.com/xdata/images/hotel/max1024x768/350246087.jpg?k=bd625fca4f2a0aad7ab9e461aa12ac27d80362a65c1005f572746b4b4b50147a&o=&hp=1'
        }
      />
      <EventSection3 />
      {/* <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;

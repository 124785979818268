import { Chip, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Product = ({ item, addToCart }) => {
  const [productItem, setProductItem] = useState({ ...item, price: 0 });
  const [error, setError] = useState(false);

  const styleField = {
    width: '25ch',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#657150',
      },
      '&:hover fieldset': {
        borderColor: '#657150',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#657150',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#5C5C5C',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#657150',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#657150',
    },
  };

  return (
    <div className="row">
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              <Zoom>
                <img src={item.proImg ? item.proImg : ''} alt="products" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details">
          <h2>{item.title}</h2>
          {/* <div className="product-rt">
            <div className="rating">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star-o"></i>
            </div>
            <span>(25 customer reviews)</span>
          </div> */}
          <div className="price" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span className="current">€{item.price}</span>
            {item.id !== 16 && (
              <Chip
                icon={<FavoriteIcon />}
                label={`Contribuito: ${item.donated}€`}
                size="medium"
                sx={{
                  '& .MuiChip-icon': {
                    color: '#DC143C',
                    fontSize: 18,
                  },
                }}
              />
            )}
          </div>
          <p>{item.description || 'No description available'}</p>
          <div className="product-option">
            <TextField
              error={error}
              onChange={(e) => {
                setError(false);
                setProductItem({ ...productItem, price: e.target.value });
              }}
              label="Inserisci il tuo contributo"
              id="outlined-start-adornment"
              sx={styleField}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginTop: '22px', marginRight: '10px' }}>
                    €
                  </InputAdornment>
                ),
              }}
            />
            <div className="product-row">
              <button
                className="theme-btn"
                onClick={() => {
                  if (!productItem.price) {
                    setError(true);
                    return;
                  }
                  addToCart(productItem);
                }}
              >
                Contribuisci
              </button>
              <div></div>
            </div>
          </div>
          {/* <div className="tg-btm">
            <p>
              <span>Categories:</span> Ornaments
            </p>
            <p>
              <span>Tags:</span> Jewellery, events, wedding
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Product;

import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { totalPrice } from '../../utils';
import ModalForm from '../Modal/ModalForm';
import './style.scss';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../store/actions/action';

const OrderRecivedSec = ({ cartList }) => {
  const dispatch = useDispatch();
  const modalFormRef = React.useRef(null);
  const handleOpenForm = () => {
    modalFormRef.current.openModal();
  };

  const attachPurchaseComment = () => {
    const purchaseComment = cartList.map((item) => {
      return ` | ${item.id} | contributo: € ${item.qty * item.price}`;
    });
    return purchaseComment.join(',');
  };

  return (
    <section className="cart-recived-section section-padding">
      <div className="container">
        <div className="row">
          <div className="order-top" style={{ gap: '20px' }}>
            <h2>
              Grazie per il tuo contributo!
              {/* <span>Your Shoping has been recived</span> */}
            </h2>
            <Link className="theme-btn" onClick={handleOpenForm}>
              Messaggio di auguri
            </Link>
            <Link to="/home" onClick={() => dispatch(clearCart())} className="theme-btn">
              Torna alla home
            </Link>
          </div>
          <Grid className="cartStatus">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid className="cartTotals">
                  <h4>Dettagli</h4>
                  <Table>
                    <TableBody>
                      {cartList.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <img src={item.proImg} alt="" /> {item.title}
                          </TableCell>
                          <TableCell align="right">€ {item.qty * item.price}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow className="totalProduct">
                        <TableCell>Numero di contributi</TableCell>
                        <TableCell align="right">{cartList.length}</TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>Sub Price</TableCell>
                        <TableCell align="right">${totalPrice(cartList)}</TableCell>
                      </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <b>Totale contributo</b>
                        </TableCell>
                        <TableCell align="right">
                          <b>€ {totalPrice(cartList)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <ModalForm ref={modalFormRef} cartList={attachPurchaseComment()} />
    </section>
  );
};

export default OrderRecivedSec;

import axios from 'axios';

const sendMessage = async ({ type, name, email, msg }) => {
  try {
    const payload = {
      type: type ?? 'normal',
      name,
      email,
      msg,
    };
    const headers = {
      'X-App-Key': 'ry26jl30md84ne85',
    };
    const response = await axios.post(
      'https://listanozzeonline.com/apiproxy/v1/list/6665a4a81ed1c394548b4572/message/',
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export default sendMessage;

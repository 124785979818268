import React from 'react';
import { JackInTheBox } from 'react-awesome-reveal';
import SectionTitle from '../SectionTitle';
import sImg1 from '../../images/twitch.jpg';
import sImg2 from '../../images/primo-appuntamento.jpg';
import sImg3 from '../../images/groot.jpg';
import sImg4 from '../../images/montagne-russe.jpg';

import flowerShape from '../../images/story/flower.svg';
import flowerShape2 from '../../images/story/flower-2.svg';
import pin from '../../images/story/pin.svg';
import ring from '../../images/story/ring.svg';

import shape1 from '../../images/story/flower-shape1.svg';
import shape2 from '../../images/story/flower-shape2.svg';
import shape3 from '../../images/story/flower-shape3.svg';
import shape4 from '../../images/story/flower-shape4.svg';

const Storys = [
  {
    sImg: sImg1,
    flower: flowerShape,
    title: 'Come ci siamo conosciuti',
    date: '12 Giu 2020',
    description:
      "Una passione comune ci ha fatto incontrare e diventare amici, non sapevamo neanche i nostri veri nomi quando abbiamo cominciato a sentirci, ma già ci divertivamo insieme 'grindando per l'Immortal'. Per due anni Zippi (Francesca) è stata Raze e Jim (Andrea) Reyna...",
  },
  {
    sImg: sImg2,
    flower: flowerShape2,
    title: 'Il nostro primo appuntamento',
    date: '14 Feb 2022',
    description:
      "Qualche tempo dopo, grazie ad una difficile confessione di Andrea, abbiamo 'colto l'attimo' e avuto il nostro primo appuntamento in quel di Milano. Avevamo finalmente capito che volevamo stare insieme.",
    order1: 'order-lg-2 order-1',
    order2: 'order-lg-1 order-2',
  },
  {
    sImg: sImg3,
    flower: flowerShape,
    title: "L'arrivo di una palla di pelo",
    date: '10 Giu 2022',
    description:
      'Un incidente e un giro al pronto soccorso inaspettato hanno portato la nostra storia a fare un passo avanti. Abbiamo iniziato a vivere insieme nella nostra amata Milano (sì, a noi piace). Nella nuova quotidianità però mancava qualcosina, così siamo andati a vedere qualche baffuto. È stato amore a prima vista, già volevamo bene alla nostra palla di pelo: Groot.',
  },
  {
    sImg: sImg4,
    flower: flowerShape2,
    title: 'Una nuova avventura',
    date: '1 Set 2024',
    description:
      "Ne abbiamo vissute tante di avventure dal 14 febbraio a oggi, tra viaggi al mare, in montagna, trasferimenti più o meno piacevoli e ora ci aspetta un anno nella grande mela. Crediamo però, che l'avventura più grande sia la vita che vivremo insieme, sempre con lo spirito con cui abbiamo affrontato le montagne russe...",
    order1: 'order-lg-2 order-1',
    order2: 'order-lg-1 order-2',
  },
];

const StorySection = (props) => {
  return (
    <section className="wpo-story-section-s2 section-padding" id="story">
      <div className="container">
        <SectionTitle subTitle={'La nostra storia'} />
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="row">
                <div className={`col col-lg-6 col-12 ${story.order1}`}>
                  <div className="wpo-story-img" style={{ height: '100%' }}>
                    <img src={story.sImg} alt="" />
                  </div>
                </div>
                <div className={`col col-lg-6 col-12 ${story.order2}`}>
                  <JackInTheBox duration="1000" triggerOnce="true">
                    <div className="wpo-story-content" style={{ minHeight: '650px' }}>
                      <div className="thumb">
                        <span>{story.date}</span>
                        <div className="pin">
                          <img src={pin} alt="" />
                        </div>
                      </div>
                      <h2>{story.title}</h2>
                      <p>{story.description}</p>
                      <div className="flower">
                        <img src={story.flower} alt="" />
                      </div>
                    </div>
                  </JackInTheBox>
                </div>
              </div>
              <div className="ring-wrap">
                {Array.apply(null, { length: 10 }).map((e, i) => (
                  <div className="ring" key={i}>
                    <img src={ring} alt="" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={shape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={shape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={shape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={shape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection;

import { createTheme } from '@mui/material/styles';
import '@fontsource/jost'; // Assicurati di aver installato il pacchetto @fontsource/jost

const theme = createTheme({
  typography: {
    fontFamily: '"Jost", sans-serif',
  },
});

export default theme;

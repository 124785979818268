import React from 'react';
import useIsMobile from '../../js/hooks.js';
import coupleImg1 from '../../images/couple/cici-navigli.jpg';
import coupleImg2 from '../../images/couple/couple-flower.png';
import vector1 from '../../images/couple/vector-1.svg';
import vector2 from '../../images/couple/vector-2.svg';
import bgShape from '../../images/couple/bg-shape.svg'; // Ensure the path is correct

const CoupleSection3 = (props) => {
  const isMobile = useIsMobile();

  return (
    <section
      className="wpo-couple-section-s3 section-padding"
      id="couple"
      style={
        isMobile
          ? {}
          : {
              backgroundImage: `url(${bgShape})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }
      }
    >
      <div className="container-fluid">
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <div className="vector">
                  <img src={vector1} alt="" />
                </div>
                <h3>Andrea - Jim</h3>
                <p>
                  "Chi l'avrebbe mai detto che una fan del "whatever it takes" sarebbe diventata una
                  streamer di Valorant?..."
                </p>
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/master96pro/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-12">
              <div className="middle-couple-pic">
                <div className="middle-couple-pic-inner">
                  <img src={coupleImg1} alt="" />
                </div>
                <div className="couple-flower">
                  <img src={coupleImg2} alt="" />
                </div>
              </div>
            </div>
            <div className="col col-xl-3 col-lg-4 col-12">
              <div className="text-grid">
                <div className="vector">
                  <img src={vector2} alt="" />
                </div>
                <h3>Francesca - Zippi</h3>
                <p>
                  "...e chi l'avrebbe mai detto che un fan di JoJo le avrebbe lasciato il follow e
                  avrebbe finito per innamorarsi di lei?"
                </p>
                <div className="social">
                  <ul>
                    <li>
                      <a href="https://x.com/OcrZippi" target="_blank" rel="noopener noreferrer">
                        <i className="ti-twitter-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/ocr_zippi/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoupleSection3;

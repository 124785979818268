import { ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: process.env.REACT_APP_AWS_REGION },
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  }),
});

const PortfolioSectionS2 = (props) => {
  const [imageKeys, setImageKeys] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // To track the currently selected image

  const fetchImages = async () => {
    const cachedKeys = sessionStorage.getItem('cachedImageKeys');

    if (cachedKeys) {
      const parsedKeys = JSON.parse(cachedKeys);
      setImageKeys(parsedKeys);
      setLightboxImages(
        parsedKeys.map((key) => ({
          src: `${process.env.REACT_APP_AWS_CLOUD_FRONT_DOMAIN}/${key}`,
        }))
      );
      return;
    }

    try {
      const command = new ListObjectsV2Command({
        Bucket: 'wedding-af',
      });
      const response = await client.send(command);

      const fetchedKeys = response.Contents?.map((item) => item.Key) || [];

      setImageKeys(fetchedKeys);
      setLightboxImages(
        fetchedKeys.map((key) => ({
          src: `${process.env.REACT_APP_AWS_CLOUD_FRONT_DOMAIN}/${key}`,
        }))
      );

      sessionStorage.setItem('cachedImageKeys', JSON.stringify(fetchedKeys));
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  return (
    <section className={`wpo-portfolio-section-s2 section-padding ${props.prClass}`} id="gallery">
      <div className="container-fluid">
        <div className="sortable-gallery">
          <div className="gallery-filters"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-grids gallery-container clearfix">
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                  <Masonry columnsCount={4} gutter="30px">
                    {imageKeys.map(
                      (image, i) =>
                        i !== 0 && (
                          <div className="grid" key={i}>
                            <div className="img-holder" onClick={() => openLightbox(i)}>
                              <img
                                src={`${process.env.REACT_APP_AWS_CLOUD_FRONT_DOMAIN}/${image}`}
                                alt={`img-${i}`}
                                className="img img-responsive"
                              />
                              <div className="hover-content">
                                <i className="ti-plus"></i>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={lightboxImages}
        index={currentIndex} // Set the current image index for the Lightbox
      />
    </section>
  );
};

export default PortfolioSectionS2;

import axios from 'axios';

const reserve = async ({ assistance, members, name, email, notes }) => {
  try {
    const payload = {
      assistance: assistance ?? 1,
      members,
      name,
      email,
      notes,
    };
    const headers = {
      'X-App-Key': 'ry26jl30md84ne85',
    };
    const response = await axios.post(
      'https://listanozzeonline.com/apiproxy/v1/list/6665a4a81ed1c394548b4572/rsvp/',
      payload,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export default reserve;

import React from 'react';
import { Slide } from 'react-awesome-reveal';
import SectionTitle from '../SectionTitle';
import sImg1 from '../../images/cascina-bine.jpg';
import sImg2 from '../../images/cerimonia.jpeg';
import sImg3 from '../../images/cascina-notte.jpg';
import LocationMap from './Modal';

const Events = [
  {
    Simg: sImg1,
    title: 'La location',
    li1: 'Sabato, 20 Luglio 2024',
    li2: 'Cascina Binè, Via Gavi, 101, 15067 Novi Ligure, AL',
    locationButton: true,
    animation: '1200',
  },
  {
    Simg: sImg2,
    title: 'La cerimonia',
    li1: 'Orario: 17:30',
    li2: 'La cerimonia si terrà nel giardino di Cascina Binè, sotto un pergolato pieno di fiori. Non preoccupatevi, promettiamo che non sarà troppo lunga!',
    locationButton: false,
    animation: '1400',
  },
  {
    Simg: sImg3,
    title: 'Apertivo e Cena',
    li1: 'Orario: 19:00',
    li2: 'Apertivio, cena e musica. Promettiamo che non mancherà il vino!',
    locationButton: false,
    animation: '1600',
  },
];

const EventSectionS3 = (props) => {
  return (
    <section className="wpo-event-section-s3 section-padding" id="event">
      <div className="container">
        <SectionTitle subTitle={'Organizzazione'} MainTitle={'Quando e dove si terrà'} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <Slide direction="up" duration={event.animation} triggerOnce="true">
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div>
                    <div
                      className="wpo-event-text"
                      style={{ height: '300px', padding: '20px', opacity: '80%' }}
                    >
                      <div className="title">
                        <h2>{event.title}</h2>
                      </div>
                      <ul style={{ color: 'black', maxWidth: '280px' }}>
                        <li>{event.li1}</li>
                        <li>{event.li2}</li>
                        <li>{event.li3}</li>
                        {event.locationButton && (
                          <li>
                            <LocationMap />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSectionS3;

import { Box, Chip } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';

const ShopProduct = ({ products, addToCartProduct, addToWishListProduct }) => {
  const navigate = useNavigate();
  const [number, setCount] = useState(8);
  const [buttonActive, setButtonState] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(10, 0);
  };

  const goToProduct = (productSlug) => {
    scrollToTop();
    navigate(`/product-single/${productSlug}`);
  };

  const donationRemaning = (product) => {
    const donated = product.donated;
    const price = product.price;
    const donation = (donated * 100) / price;
    return donation > 100 ? 100 : donation.toFixed(0);
  };

  return (
    <section className="wpo-product-section section-padding">
      <div className="container">
        <div className="wpo-product-wrap">
          <div className="row">
            {products.length > 0 &&
              products.slice(0, number).map((product, pitem) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-12" key={pitem}>
                  <div className="wpo-product-item">
                    <div className="wpo-product-img">
                      <img src={product.proImg} alt="" />
                      <ul>
                        <li>
                          {/* <button
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Add to Wishlist"
                            onClick={() => addToWishListProduct(product)}
                          >
                            <i className="fi flaticon-heart"></i>
                          </button> */}
                        </li>
                        <li>
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Contribuisci"
                            onClick={() => goToProduct(product.slug)}
                          >
                            Contribuisci
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="wpo-product-text">
                      <Box
                        id="contributo-box"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <h3 style={{ height: '30px' }}>
                          <Link onClick={scrollToTop} to={`/product-single/${product.slug}`}>
                            {product.title}
                          </Link>
                        </h3>
                        <ul
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <li>{product.delPrice}</li>
                          <li>€{product.price}</li>
                          {product.id !== 16 && (
                            <Chip
                              icon={<FavoriteIcon />}
                              label={'Contribuito: ' + donationRemaning(product) + '%'}
                              size="medium"
                              sx={{
                                '& .MuiChip-icon': {
                                  color: '#DC143C',
                                  fontSize: 18,
                                },
                              }}
                            />
                          )}
                        </ul>
                      </Box>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={`load-btn ${buttonActive ? 'd-none' : ''}`}>
            <span onClick={() => setButtonState(!buttonActive)}>
              <button className="loadmore-btn theme-btn" onClick={() => setCount(number + number)}>
                Vedi altri
              </button>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopProduct;

import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PartnerSection from '../../components/PartnerSection';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';

const GalleryPage = () => {
  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-3'} />
      <PageTitle pageTitle={'Album'} pagesub={'Album'} />
      <PortfolioSectionS2 prClass={'pb-0 pt-120'} />
      <PartnerSection pClass={'section-padding'} />
      <Footer footerClass={'wpo-site-footer-s2'} />
      <Scrollbar />
    </Fragment>
  );
};

export default GalleryPage;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';
import theme from '../../js/theme';

const Modal = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <DialogContentText sx={{ fontSize: '1.3rem' }}>{props.body}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '20px',
          }}
        >
          <Button
            onClick={props.handleClose}
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#657150',
              '&:hover': {
                backgroundColor: '#556b2f', // colore quando il pulsante è hoverato (passaggio del mouse)
              },
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Modal;

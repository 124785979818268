import React from 'react';

const SectionTitle = (props) => {
  return (
    <div className={`wpo-section-title ${props.s2Class}`}>
      <span
        style={{
          fontSize: '56px',
        }}
      >
        {props.subTitle}
      </span>
      <h2 style={{ marginTop: '20px' }}>{props.MainTitle}</h2>
    </div>
  );
};

export default SectionTitle;

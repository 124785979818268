import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { totalPrice } from '../../utils';
import { removeFromCart, incrementQuantity, decrementQuantity } from '../../store/actions/action';

const CartPage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { carts } = props;

  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-3'} />
      <PageTitle pageTitle={'Contributi'} pagesub={'Contributi'} />
      <div className="cart-area section-padding">
        <div className="container">
          <div className="form">
            <div className="cart-wrapper">
              <div className="row">
                <div className="col-12">
                  <form action="cart">
                    <table className="table-responsive cart-wrap">
                      <thead>
                        <tr>
                          <th className="images images-b"></th>
                          <th className="product-2">Regali</th>
                          <th className="pr">Contributo</th>
                          {/* <th className="ptice"></th>
                          <th className="stock">Contributo</th> */}
                          <th className="remove remove-b"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {carts &&
                          carts.length > 0 &&
                          carts.map((catItem, crt) => (
                            <tr key={crt}>
                              <td className="images">
                                <img src={catItem.proImg} alt="" />
                              </td>
                              <td className="product">
                                <ul>
                                  <li className="first-cart">{catItem.title}</li>
                                  {/* <li>Brand : {catItem.brand}</li>
                                  <li>Size : {catItem.size}</li> */}
                                </ul>
                              </td>
                              {/* <td className="stock">
                                <div className="pro-single-btn">
                                  <Grid className="quantity cart-plus-minus">
                                    <Button
                                      className="dec qtybutton"
                                      onClick={() => props.decrementQuantity(catItem.id)}
                                    >
                                      -
                                    </Button>
                                    <input value={catItem.qty} type="text" />
                                    <Button
                                      className="inc qtybutton"
                                      onClick={() => props.incrementQuantity(catItem.id)}
                                    >
                                      +
                                    </Button>
                                  </Grid>
                                </div>
                              </td> */}
                              <td className="ptice">€{catItem.qty * catItem.price}</td>
                              {/* <td className="stock">${catItem.qty * catItem.price}</td> */}
                              <td className="action">
                                <ul>
                                  <li
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Rimuovi"
                                    className="w-btn"
                                    onClick={() => props.removeFromCart(catItem.id)}
                                  >
                                    <i className="fi ti-trash"></i>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </form>
                  {/* <div className="submit-btn-area">
                    <ul>
                      <li>
                        <Link onClick={ClickHandler} className="theme-btn" to="/shop">
                          Fai altri contributi{' '}
                        </Link>
                      </li>
                      <li>
                        <button type="submit">Update Cart</button>
                      </li>
                    </ul>
                  </div> */}
                  <div className="cart-product-list">
                    <ul>
                      <li>
                        Numero di contributi<span>( {carts.length} )</span>
                      </li>
                      {/* <li>
                        Sub Price<span>${totalPrice(carts)}</span>
                      </li>
                      <li>
                        Vat<span>$0</span>
                      </li>
                      <li>
                        Eco Tax<span>$0</span>
                      </li>
                      <li>
                        Delivery Charge<span>$0</span>
                      </li> */}
                      <li className="cart-b">
                        Totale contributo<span>€{totalPrice(carts)}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="submit-btn-area">
                    <ul>
                      <li>
                        {carts.length === 0 ? (
                          <span
                            className="theme-btn disabled-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            Procedi al pagamento
                          </span>
                        ) : (
                          <Link onClick={ClickHandler} className="theme-btn" to="/checkout">
                            Procedi al pagamento
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer footerClass={'wpo-site-footer-s2'} />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};
export default connect(mapStateToProps, {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
})(CartPage);

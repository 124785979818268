import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Slide,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import theme from '../../js/theme';
import React, { useState } from 'react';
import sendMessage from '../../api/message';
import mailImg from '../../images/mail-icon.png';
import { Link } from 'react-router-dom';

const giftMessageModel = {
  name: '',
  email: '',
  msg: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessMessage = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Grazie!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 18 }}>
          Il tuo messaggio è stato inviato agli sposi.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Link onClick={handleClose} className="view-cart-btn s1">
            chiudi
          </Link>
        </Box>
      </Box>
    </Modal>
  );
};

const ModalForm = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [giftMessageForm, setGiftMessageForm] = useState(giftMessageModel);
  const [success, setSuccess] = useState(false);

  React.useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setGiftMessageForm(giftMessageModel);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGiftMessageForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(giftMessageForm).forEach((key) => {
      if (!giftMessageForm[key]) {
        newErrors[key] = true;
      }
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const fullMessage = giftMessageForm.msg + props.cartList;
    const formToSend = { ...giftMessageForm, msg: fullMessage };
    sendMessage(formToSend).then((response) => {
      if (response.code === 200) {
        handleClose();
        setSuccess(true);
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        slotProps={{
          backdropProps: {
            onClick: handleClose,
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'center' }}>
            <Box sx={{ marginBottom: '20px' }}>
              <img src={mailImg} alt="mail-icon" style={{ width: '100px', height: '100px' }} />
            </Box>
            'Invia il tuo messaggio di auguri'
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              error={errors.name}
              helperText={errors.name ? 'Inserisci il tuo nome e cognome' : ''}
              sx={{ marginTop: 5, width: '50%' }}
              required
              onChange={handleChange}
              name="name"
              type="text"
              id="name"
              label="Nome e Cognome"
              placeholder="Inserisci il tuo nome e cognome"
            />
            <TextField
              error={errors.email}
              helperText={errors.email ? 'Inserisci un indirizzo email valido' : ''}
              sx={{ marginTop: 5, width: '50%' }}
              required
              onChange={handleChange}
              name="email"
              type="email"
              id="email"
              label="Email"
              placeholder="Inserisci la tua email"
            />
            <TextField
              error={errors.message}
              helperText={errors.message ? 'Inserisci il tuo messaggio di auguri' : ''}
              sx={{ marginTop: 5, width: '50%' }}
              required
              onChange={handleChange}
              name="msg"
              type="text"
              id="message"
              label="Messaggio"
              multiline
              rows={4}
              placeholder="Inserisci il tuo messaggio di auguri"
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                color: 'white',
                backgroundColor: '#657150',
                '&:hover': {
                  backgroundColor: '#556b2f',
                },
              }}
            >
              Invia
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {success && <SuccessMessage />}
    </ThemeProvider>
  );
});

export default ModalForm;

import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Contactpage from '../../components/Contactpage/Contactpage';

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-3'} />
      <PageTitle pageTitle={'Contattaci'} pagesub={'Contatti'} />
      <Contactpage />
      <Footer footerClass={'wpo-site-footer-s2'} />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;

import React, { Fragment } from 'react';

import { Button, Dialog, Grid } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const LocationMap = ({ maxWidth, button, buttonClass }) => {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <MuiDialogTitle>
        {onClose ? (
          <IconButton className="event-close-btn" aria-label="close" onClick={onClose}>
            <i className="ti-close"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <Fragment>
      <Button className={`btn ${buttonClass}`} onClick={handleClickOpen}>
        {button}
        Guarda su Maps
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {' '}
        </DialogTitle>
        <Grid className="modalBody modal-body">
          <Grid className="modalBody modal-body">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2834.17951519914!2d8.788460576599379!3d44.73635428170595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478767bbe8e9736d%3A0xd9466b68aa24d27e!2sCascina%20Bin%C3%A8!5e0!3m2!1sit!2sit!4v1717522782124!5m2!1sit!2sit"></iframe>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default LocationMap;
